import React from "react";
import "./International.css";
import Card from "../Card/Card";
import Dubai from "../../img/dubai.jpg";
import Malaysia from "../../img/malaysia.jpg";
import Thailand from "../../img/thailand.jpg";
import Maldives from "../../img/maldives.jpg";
import Bali from "../../img/bali.jpg";
import Istanbul from "../../img/istanbul.jpg";


const International = () => {

  return (
    <div className="services mar-top" id="International">
      <span className=" head-txt" > International Tour Packages</span>
      <div className="grid-container">
      <div className="blur"></div>
        <Card
          img={Dubai}
          heading={"Dubai"}
          detail={"Dubai is a city and emirate in the UAE known for luxury shopping, ultramodern architecture and a lively nightlife scene. "}
        />
        <Card
          img={Maldives}
          heading={"Maldives"}
          detail={"The Maldives is the smallest country in Asia. Including the sea, the territory spans roughly 90,000 square kilometres."}
        />

        <Card
          img={Istanbul}
          heading={"Istanbul"}
          detail={"Istanbul is a major city in Turkey that straddles Europe and Asia across the Bosphorus Strait."}
        />
        <Card
          img={Malaysia}
          heading={"Malaysia"}
          detail={"Malaysia is a Southeast Asian country occupying parts of the Malay Peninsula and the island of Borneo."}
        />

        <Card
          img={Thailand}
          heading={"Thailand"}
          detail={"Thailand is a Southeast Asian country. It's known for tropical beaches, opulent royal palaces, ancient ruins and ornate temples."}
        />
        <Card
          img={Bali}
          heading={"Bali"}
          detail={"Bali is the most popular Indonesian island known for its natural settings, scenic beaches, traditional culture, rich history, creativity, art."}
        />
      </div>
      </div>
  );
};

export default International;

