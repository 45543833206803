import React from "react";
import Card from "../Card/Card";
import Munnar from "../../img/Munnar.jpg";
import Kochi from "../../img/kochi.jpg";
import Thekkady from "../../img/thekkady.jpg";
import Alapuzha from "../../img/alapuzha.jpg";
import Kovalam from "../../img/kovalam.jpg";
import Wayanad from "../../img/wayanad.jpg";

const Package = () => {

  return (
    <div className="services mar-top" id="package">
      <h1 className=" head-txt" >Kerala Tour Packages</h1>
      <div className="blur" ></div>
      <div className="grid-container">
        <Card
          img={Kochi}
          heading={"Kochi"}
          detail={"Kochi known as the Queen of the Arabian Sea,  Kochi had been a main port city of Indian Peninsula. "}
        />
        <Card
          img={Munnar}
          heading={"Munnar"}
          detail={"Munnar is a town in the Western Ghats mountain range in  Kerala . it's surrounded by rolling hills dotted with tea plantations "}
        />

        <Card
          img={Thekkady}
          heading={"Thekkady"}
          detail={"Thekkady is a town near Periyar National Park,, which is a haven for hikers and animal lovers.."}
        />
        <Card
          img={Alapuzha}
          heading={"Alapuzha"}
          detail={"Alappuzha  is a city on the Laccadive Sea . It's best known for houseboat cruises along the rustic Kerala backwaters."}
        />
        <Card
          img={Kovalam}
          heading={"Kovalam"}
          detail={"Kovalam is a small coastal town in the southern Indian state of Kerala, south of Thiruvananthapuram."}
        />
        <Card
          img={Wayanad}
          heading={"Wayanad"}
          detail={"Wayanad, the green paradise is nestled among the mountains of the Western Ghats, forming the border world ."}
        />
      </div>
    </div>

  );
};

export default Package;

