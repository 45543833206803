import Navbar from "./components/Navbar/Navbar";
import Intro from "./components/Intro/Intro";
import International from "./components/International/International";
import "./App.css";
import Domestic from "./components/Domestic/Domestic";
import Packages from "./components/Package/Package";
import Takeair from "./components/Takeair/Takeair";
import Footer from "./components/Footer/Footer";
function App() {
  return (
    <div
      className="App">
      <Navbar />
      <Intro />
      <International />
      <Domestic />
      <Packages />
      <Takeair />
      <Footer />
    </div>
  );
}

export default App;
