import React from "react";
import "./Footer.css";
import Wave from "../../img/wave.png";
import Insta from "@iconscout/react-unicons/icons/uil-instagram";
import Facebook from "@iconscout/react-unicons/icons/uil-facebook";
import Youtube from "@iconscout/react-unicons/icons/uil-youtube";
import Whatsapp from "@iconscout/react-unicons/icons/uil-whatsapp";

const Footer = () => {
  return (
    <div className="footer">
      <img src={Wave} alt="" style={{ width: "100%" }} />
      <div className="f-content">
        <a className="f-link" href='mailto:takeairholidays@gmail.com?subject=Me&body=Hello!'>takeairholidays@gmail.com</a>
        <div className="f-icons">
          <a href="https://web.whatsapp.com/send?phone=+919037190911">
            <Whatsapp color="white" size={"3rem"} />
          </a>
          <a href="https://www.instagram.com/takeairholidays?igsh=MTV1ODBxc21qb2gyYg==">
            <Insta color="white" size={"3rem"} />
          </a>
          <a href="https://www.facebook.com/share/Gser48Q2vm2YEi6w/?mibextid=LQQJ4d">
            <Facebook color="white" size={"3rem"} />
          </a>
          <a href="https://www.youtube.com/@TakeAir-qi2pm">
            <Youtube color="white" size={"3rem"} />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
