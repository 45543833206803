import React from "react";
import "./Intro.css";
const Intro = () => {


  return (
    <div className="Intro" id="Intro">
    </div>
  );
};

export default Intro;
