import React from "react";
import Phone from "@iconscout/react-unicons/icons/uil-phone";
import "./Navbar.css";
import { Link } from "react-scroll";
import logo from "../../img/logo.png";
import slogan from "../../img/slogan.png";
const navbar = () => {
  return (
    <div className="n-wrapper" id="Navbar">
      {/* left */}
      <div className="n-left">
        <img className="n-logo" src={logo} alt="" />
        <img className="n-slogan" src={slogan} alt="" />
        {/* <Toggle /> */}
      </div>
      {/* right */}
      <div className="n-right">
        <div className="n-list">
          <ul style={{ listStyleType: "none" }}>
            <li>
              <Link activeClass="active" className="n-font" to="Navbar" spy={true} smooth={true}>
                HOME
              </Link>
            </li>
            <li>
              <Link to="International" className="n-font" spy={true} smooth={true}>
                INTERNATIONAL
              </Link>
            </li>
            <li>
              <Link to="domestic" className="n-font" spy={true} smooth={true}>
                DOMESTIC
              </Link>
            </li>
            <li>
              <Link to="package" className="n-font" spy={true} smooth={true}>
                KERALA
              </Link>
            </li>
            <li>
              <Link to="takeair" className="n-font" spy={true} smooth={true}>
                CONTACT
              </Link>
            </li>
          </ul>
        </div>
          <button className="button n-button ">
          <a href="tel:903-719-0911">
            <Phone color="white" size={"1rem"} />
            +919037190911</a>
          </button>
      </div>
    </div>
  );
};

export default navbar;
