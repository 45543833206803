import React from "react";
import Card from "../Card/Card";
import Kashmir from "../../img/kashmir.jpg";
import Manali from "../../img/manali.jpg";
import Agra from "../../img/agra.jpg";
import Rajasthan from "../../img/rajasthan.jpg";
import Goa from "../../img/goa.jpg";
import Hyderabad from "../../img/hyderabad.jpg";



const Domestic = () => {
  return (
    <div className ="services mar-top" id="domestic">
        <h1  className=" head-txt " >  Domestic Tour Packages</h1>
        <div className="blur "></div>
        <div className="grid-container">
                <Card
                  img={Kashmir}
                  heading={"Kashmir"}
                  detail={"Kashmir is the northernmost geographical region of the India .Kashmir, a piece of heaven on earth, it is located mostly all in the Himalayas."}
                />
                <Card
                  img={Goa}
                  heading={"Goa"}
                  detail={"Goa is a state in western India with coastlines stretching along the Arabian Sea. Goa is also known for its beaches and 17th-century churches."}
                />
            
                <Card
                  img={Manali}
                  heading={"Manali"}
                  detail={"Manali is a high-altitude Himalayan resort town in India’s northern Himachal Pradesh state. It has a reputation as a backpacking center ."}
                />
                     <Card
                  img={Agra}
                  heading={"Agra"}
                  detail={"Agra is a city on the banks of the Yamuna river in the Indian state of Uttar Pradesh.Taj Mahal, build by Shah Jahan in remembrance of his wife Mumtaz Mahal."}
                />
                 <Card
                  img={Rajasthan}
                  heading={"Rajasthan"}
                  detail={"Rajasthan is a state in northwestern India. Jaisalmer is  a princely state , in the heart of the Thar Desert. Known as the 'Golden City'."}
                />
            
                 <Card
                  img={Hyderabad}
                  heading={"Hyderabad"}
                  detail={"Hyderabad is the capital of southern India's Telangana state. A major center for the technology industry, it's home to many upscale restaurants and shops."}
                />
           </div>
    </div>
   
  );
};



export default Domestic;
