import "./Takeair.css";
import React from "react";
import Logo from "../../img/logo.png";
import Instagram from "../../img/instagram.png";
import Facebook from "../../img/Facebook.png";
import Youtube from "../../img/youtube.png";
import Whatsapp from "../../img/whatsapp.png";
import Phone from "@iconscout/react-unicons/icons/uil-phone";


import { motion } from "framer-motion";
import { Link } from 'react-scroll'
const Takeair = () => {
  return (
    <div className="takeair" id="takeair">
      <div className="w-left">
        <div className="awesome">
          <span className="a-txt" >
            Take Air Holidays
          </span>
          <span></span>
          <span className="awesome-txt">
            Take to the skies and unlock a world of wonder! With Take Air Holidays,
            experience the thrill of exploring new destinations and creating unforgettable memories.
            We mainly focus on tailored group tour packages for corporate, fostering a sense of community
            and camaraderie among members. Our expertly curated packages cater to diverse interests and ages,
            ensuring an unforgettable experience for all.Other services mainly include booking hotels,
            resorts,transport and other accommodations worldwide, including luxury and budget options.
          </span>
          <Link to="tel:9037190911" spy={true} smooth={true}>
            <button className="button m-button ">
              <Phone color="white" size={"1rem"} />
              +919037190911
            </button>
          </Link>
        </div>

        {/* right side */}
      </div>
      <div className="w-right">
        <motion.div
          initial={{ rotate: 45 }}
          whileInView={{ rotate: 0 }}
          viewport={{ margin: "-40px" }}
          transition={{ duration: 3.5, type: "spring" }}
          className="w-mainCircle"
        >
          <div className="w-secCircle">
            <a href="https://web.whatsapp.com/send?phone=+919037190911">
              <img className="icon-s" src={Whatsapp} alt="" />
            </a>
          </div>
          <div className="w-secCircle">
            <a href="https://www.instagram.com/takeairholidays?igsh=MTV1ODBxc21qb2gyYg==">
              <img className="icon-s" src={Instagram} alt="" />
            </a>
          </div>
          <div className="w-secCircle">
            <img src={Logo} alt="" />
          </div>{" "}
          <div className="w-secCircle">
            <a href="https://www.youtube.com/@TakeAir-qi2pm">
              <img className="icon-s" src={Youtube} alt="" />
            </a>
          </div>
          <div className="w-secCircle">
            <a href="https://www.facebook.com/share/Gser48Q2vm2YEi6w/?mibextid=LQQJ4d">
              <img className="icon-s" src={Facebook} alt="" />
            </a>
          </div>
        </motion.div>
        {/* background Circles */}
        <div className="w-backCircle lightCircle"></div>
        <div className="w-backCircle darkCircle"></div>
      </div>
    </div>
  );
};
export default Takeair;
