import React from "react";
import "./Card.css";


const Card = ({ img, heading, detail }) => {
  return (
    <div className="card">
           <span className="card-txt">{heading}</span>
      <img src={img} alt="" />
      <div className="container">
        <span className="card-sub-txt">{detail}</span>
      </div>
    </div>
  );
};

export default Card;
